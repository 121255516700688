var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v("Последние расчеты")]),(!_vm.calcs.length)?_c('div',{staticClass:"list-empty"},[_vm._v(" Список расчетов пуст ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.calcs,"options":_vm.options,"server-items-length":_vm.totalCalcs,"loading":_vm.loading.calcList,"show-select":"","hide-default-footer":"","_footer-props":{ showFirstLastPage: true }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.position",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPosition(item.position))+" ")]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.start_at).format('DD.MM.YYYY HH:mm:ss'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.candidates_count)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showResult(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/profile/arrowGo.svg")}})]):_c('v-chip',{attrs:{"color":"error","small":"","outlined":""}},[_vm._v("Ошибка расчета")])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('v-data-footer',{attrs:{"pagination":props.pagination,"options":props.options,"items-per-page-text":"Расчетов на странице:","items-per-page-options":[10, 20, 50, 100, 500, 1000],"page-text":("Всего: " + (props.pagination.itemsLength) + ". Страница " + (props.options.page) + " из " + (props.pagination.pageCount)),"show-current-page":"","show-first-last-page":""},on:{"update:options":_vm.loadCalcs}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }