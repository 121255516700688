<template>
  <div>
    <v-btn text class="px-0" @click="$emit('back')"><v-icon>mdi-arrow-left-thin</v-icon>Аналитика</v-btn>
    <div class="d-flex align-center mb-5 mt-1">
      <h2 class="page-title">Соответствия профессии</h2>
      <div class="d-flex align-center ml-4 mt-1 cursor-pointer" @click="showHelp=true">
        <v-icon small class="mr-1" color="primary">mdi-information-slab-circle</v-icon>
        <div class="text-caption">Как пользоваться </div>
      </div>

    </div>
    <div class="analytic-setup-bg">
      <div class="d-flex justify-space-between">
        <h3 class="mb-2">Профессии</h3>
        <v-btn color="blue-grey lighten-3" text @click="clearProfs()">Очистить</v-btn>
      </div>
      <div class="items-list-wrapper">
        <div v-if="!anSetup.profs || !anSetup.profs.length" class="list-empty">
          Добавьте одну или несколько профессий по которым будем проверять кандидатов
        </div>
        <transition-group v-else class="items-list" name="list-complete" tag="div">
          <div class="added-item list-complete-item" v-for="itemId in anSetup.profs" :key="itemId">
            {{getProfName(itemId)}}
            <v-btn small icon color="primary" @click="deleteProf(itemId)"><v-icon small>mdi-close</v-icon></v-btn>
          </div>
        </transition-group>
        <v-btn text color="primary" @click="showAddProfgramsDialog=true">+ Добавить</v-btn>
      </div>

      <div class="d-flex justify-space-between">
        <h3 class="mb-2">Кандидаты</h3>
        <v-btn color="blue-grey lighten-3" text @click="clearPersons()">Очистить</v-btn>
      </div>
      <div class="items-list-wrapper">
        <div v-if="!persons || !persons.length" class="list-empty">
          Добавьте кандидата, менеджера или компанию для проверки соответствия профессии
        </div>
        <transition-group v-else class="items-list" name="list-complete" tag="div">
          <div class="added-item list-complete-item" v-for="item in persons" :key="item.id"
            :class="{'added-manager': item.type==='manager', 'added-client': item.type==='client', 'added-loading': getPersonLoading(item)}"
          >
            <v-progress-circular v-if="getPersonLoading(item)" :size="16" color="primary" indeterminate/>
            <span v-else>{{getPersonName(item)}}</span>
            <v-btn small icon color="primary" @click="deletePerson(item)"><v-icon small>mdi-close</v-icon></v-btn>
          </div>
        </transition-group>
        <v-btn text color="primary" @click="showAddPersonDialog=true">+ Добавить</v-btn>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <v-select  item-value="id" v-model="anSetup.calcType" :items="calcTypes" label="Метод расчета" outlined dense hide-details />
        </div>
        <div class="col-8 text-right">
          <v-btn color="primary" class="px-10" @click="startCalc()" :disabled="canCalc">Рассчитать</v-btn>
        </div>
      </div>

    </div>

    <how-use-panel v-model="showHelp" />
    <AddProfgramsDialog v-model="showAddProfgramsDialog" :srcSetup="anSetup" @ok="save()"/>
    <AddPersonDialog v-model="showAddPersonDialog" :srcSetup="anSetup" @ok="personsOk()"/>
    <CalcProgressDialog v-model="loading.calc" :progress="progress.cur" :total="progress.total" @stop="stopCalc()"/>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import HowUsePanel from './HowUsePanel.vue';
import AddProfgramsDialog from './AddProfgramsDialog.vue';
import AddPersonDialog from './AddPersonDialog.vue';
import CalcProgressDialog from './CalcProgressDialog.vue';
import api from '@/common/api';

const anSetupDefault = {
  profs: [
  ],
  candidates: [
  ],
  managers: [
  ],
  clients: [
  ],
  calcType: 'ranges'
}
const anSetupKey = "AnSetup2";

export default {
  name: 'AnalyticsSetupTab',
  components: {
    HowUsePanel, AddProfgramsDialog, AddPersonDialog, CalcProgressDialog
  },
  data() {
    return {
      showHelp: false,
      showAddProfgramsDialog: false,
      showAddPersonDialog: false,
      anSetup: {},
      candidateNames: {},
      // calcType: 'ranges',
      calcTypes: [{ id: 'peaks', text: "По пикам" }, { id: 'ranges', text: "По диапазонам" }],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      allProfs: 'analytics/profs',
      managers: 'users/managers/managers',
      clients: 'users/clients/clients',
      positions: 'analytics/positions',
      progress: 'analytics/progress',
      loading: 'analytics/loading',
    }),
    canCalc() {
      return !this.anSetup.profs || !this.anSetup.profs.length
      // || !this.anSetup.persons || !this.anSetup.persons.length
    },
    persons() {
      return [
        ...this.anSetup.managers ? this.anSetup.managers.map(el=>({
          id: el, type:'manager'
        })) : [],
        ...this.anSetup.clients ? this.anSetup.clients.map(el=>({
          id: el, type:'client'
        })) : [],
        ...this.anSetup.candidates ? this.anSetup.candidates.map(el=>({
          id: el,
          type:'candidate',
        })) : [],
      ]
    }
  },
  methods: {
    ...mapActions({
      loadCandidate: 'users/candidate/loadCandidate',
      calcProfs: 'analytics/calc',
      loadResult: 'analytics/loadResult',
      loadCalcList: 'analytics/loadCalcList',
    }),
    ...mapMutations({
      stopCalcProfs: 'analytics/stopCalc',
    }),
    deletePerson(person) {
      if (person.type==='candidate')
        this.anSetup.candidates = this.anSetup.candidates.filter(p => p!==person.id)
      if (person.type==='manager')
        this.anSetup.managers = this.anSetup.managers.filter(p => p!==person.id)
      if (person.type==='client')
        this.anSetup.clients = this.anSetup.clients.filter(p => p!==person.id)
    },
    deleteProf(id) {
      this.anSetup.profs = this.anSetup.profs.filter(p => p!==id)
      this.save()
    },
    getProfName(id) {
      const prof = this.allProfs.find(el => el.id===id)
      return prof ? prof.name : "Профессия не найдена"
    },
    getPersonName(person) {
      if (person.type==='candidate') {
        return this.candidateNames[person.id] ?? 'Кандидат не найден' + person.id
      } else
      if (person.type==='manager') {
        let item = this.managers.find(el => el.id===person.id)
        return item ? item.first_name + ' ' + item.last_name : 'Менеджер не найден' + person.id
      }
      if (person.type==='client') {
        let item = this.clients.find(el => el.id===person.id)
        return item ? item.company : 'Компания не найдена' + person.id
      }
      return person.id
    },
    getPersonLoading(person) {
      if (person.type==='candidate') {
        return !this.candidateNames[person.id]
      }
      return false
    },
    save() {
      localStorage.setItem(anSetupKey, JSON.stringify(this.anSetup))
    },
    loadCandidateNames() {
      for (let i = this.anSetup.candidates.length - 1; i >= 0; i--) {
        const el = this.anSetup.candidates[i]
        if (!this.candidateNames[el]) {
          api.get(`/users/candidates/${el}/`).then(response => {
            this.$set(this.candidateNames, el, response.first_name + ' ' + response.last_name)
          })
        }
      }
    },
    personsOk() {
      this.save()
      this.loadCandidateNames()
    },
    clearPersons() {
      this.anSetup.candidates = []
      this.anSetup.managers = []
      this.anSetup.clients = []
      this.save()
    },
    clearProfs() {
      this.anSetup.profs = []
      this.save()
    },
    async startCalc() {
      if (this.loading.calc) {
        return;
      }

      const payload = {
          candidates: this.anSetup.candidates,
          // corp_client: this.user.id,
          professiograms: this.anSetup.profs,
          chapters: [],
          method: this.anSetup.calcType,
          position: this.positions[0].id,
      }
      const response = await this.calcProfs({payload, calcBlockSize: 3} );
      this.loadResult()
      this.loadCalcList()
      this.$emit('viewResult')

    },
    stopCalc() {
      // if (this.loading.calc) {
        console.log('stopCalc')
        this.stopCalcProfs(true)
      // }

    }
  },
  created() {
    const str = localStorage.getItem(anSetupKey)
    if (str)
      this.anSetup = JSON.parse(str)
    else
      this.anSetup = cloneDeep(anSetupDefault)
    this.loadCandidateNames()
  },
  beforeDestroy() {
    this.save()
  }
};
</script>

<style scoped lang="scss">

.analytic-setup-bg {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.items-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 8px;
  background: #F9FBFC;
  border: 1px dashed #D3DBEF;
  margin-bottom: 20px;
}

.add-button {
  color: #3FB2FF;
}
.list-empty {
  color: #989FB9;
  font-size: 13px;
}

.items-list {
}

.added-item {
  background: #DDF1FE;
  color: #25325F;
  font-size: 12px;
  border-radius: 5px;
  padding: 0px 3px 0px 10px;
  margin: 4px 5px;
}

.added-item.added-manager {
  background: #ddfef0;
}
.added-item.added-client {
  background: #fef4dd;
}
.added-item.added-loading {
  width: 140px;
}

</style>
