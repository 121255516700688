<template>
  <v-dialog v-model="isDialogVisible" max-width="650">
    <v-card class="add-person-dialog">
      <v-btn class="button-close ma-4" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Выбор кандидатов
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" grow class="mb-3">
          <v-tab no-caps :value="0">Кандидаты</v-tab>
          <v-tab :value="1">Менеджеры</v-tab>
          <v-tab :value="2">Компании</v-tab>
        </v-tabs>
        <!-- <v-text-field v-model="strFilter" prepend-inner-icon="mdi-magnify" label="Фильтр" placeholder="" outlined dense>
          <template v-slot:append>
             <v-btn text icon style="margin-top:-5px" @click="strFilter=''"><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </v-text-field> -->
        <v-tabs-items v-model="tab" >
          <v-tab-item :value="0" key="0">
              <CandidatesTableSimple :selected="selectedCandidates" />
          </v-tab-item>
        <v-tab-item :value="1" >
            <ManagersTableSimple :selected="selectedManagers" />
          </v-tab-item>
          <v-tab-item :value="2">
            <CompanyTableSimple :selected="selectedClients" />
          </v-tab-item>
      </v-tabs-items>
        <div class="d-flex justify-space-between mt-2 mb-3" >
          <v-btn @click="close()">Отмена</v-btn>
          <v-btn @click="save()" color="primary">Применить</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import CandidatesTableSimple from './CandidatesTableSimple.vue';
import ManagersTableSimple from './ManagersTableSimple.vue';
import CompanyTableSimple from './CompanyTableSimple.vue';

export default {
name: 'AddProfrgamsDialog',
components: {
  CandidatesTableSimple, ManagersTableSimple, CompanyTableSimple
},
model: {
  prop: 'value',
  event: 'input',
},
props: {
  value: Boolean,
  srcSetup: Object,
},
data: () => ({
  tab: 'cand',
  strFilter: "",
  selectedCandidates: [],
  selectedManagers: [],
  selectedClients: [],
}),
watch: {
  async value(val) {
    if (val) {
      this.selectedCandidates = cloneDeep(this.srcSetup.candidates ?? [])
      this.selectedManagers = cloneDeep(this.srcSetup.managers ?? [])
      this.selectedClients = cloneDeep(this.srcSetup.clients ?? [])
      // await this.loadCustomers();
    }
  },
},
computed: {
  ...mapGetters({
    // candidates: 'users/candidates/candidates',
    // loading: 'profgrams/bulkcalc/loading',
    // selectedCandidates: 'users/candidates/selected',
    user: 'auth/user',
  }),
  isDialogVisible: {
    get() {
      return this.value;
    },
    set(value) {
      if (!value) {
        this.close()
      }
    },
  },
  visibleChapters() {
    return this.chapters;
    // return this.chapters.filter(el => this.$store.getters['profgrams/chapters/isChapterVisible'](el, this.user, false))
  },
  filter () {
      return (item, search, textKey) => {
        return item[textKey].indexOf(search) > -1
      }
    },

},
methods: {
  ...mapActions({
  }),
  ...mapMutations({
  }),
  close(ret) {
    this.$emit('input', ret);
  },
  save(ret) {
    this.srcSetup.candidates = cloneDeep(this.selectedCandidates)
    this.srcSetup.managers = cloneDeep(this.selectedManagers)
    this.srcSetup.clients = cloneDeep(this.selectedClients)
    this.$emit('ok', this.srcSetup.profs);
    this.close()
  },
  // getSelectedCount(item) {
  //   if (!item.children || !item.children.length)
  //     return;
  //   const cnt = item.children?.reduce((acc, el) => acc + (this.selection.includes(el.id) ? 1 : 0), 0)
  //   return cnt ? " ("+cnt+")" : '';
  // }
}

}
</script>

<style>
.add-person-dialog .v-tab {
  text-transform: capitalize !important;
  font-weight: bold;
}

.add-person-dialog .v-data-footer__pagination{
  margin: 0 5px;
}

.tree-view-container {
height: 300px;
overflow: auto;
border: 1px solid #ccc;
border-radius: 3px;
}

.selected-count {
font-size: 12px;
}
</style>
