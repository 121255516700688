<template>
  <div>
    <v-btn text class="px-0" @click="$emit('back')"><v-icon>mdi-arrow-left-thin</v-icon>Аналитика</v-btn>
    <h2 class="page-title">Соответствия профессии</h2>

    <div v-if="loading.positions || loading.profs || loading.loadResults" class="h-100 my-7 d-flex justify-center align-center">
        <v-progress-circular :size="25" color="primary" indeterminate />
    </div>
    <div v-else class="_mx-4" ref="setupCard">
          <!-- <div class="mt-3 d-flex"> -->
            <!-- <v-spacer /> -->
            <v-btn _v-if="$store.getters['auth/isAllowed']('download_testresultscompliencereport')"
              class="_w-100 mt-3 mb-5" color="primary" :disabled="!candidates2show.length" :loading="loading.excel" @click="toExcel()">
              Выгрузка в Excel
            </v-btn>
          <!-- </div> -->
    </div>

    <v-data-table
        :headers="candidates2show.length ? allHeaders : []"
        :items="candidates2show"
        show-select v-model="candidatesForExport"
        class="px-0 _px-sm-5 elevation-1"
        sort-by="id" sort-desc must-sort
        :loading="loading.loadingResult" loading-text="Идет загрузка... Пожалуйста подождите"
        :mobile-breakpoint="300" :items-per-page="-1" :height="tableHeight" fixed-header hide-default-footer
    >
        <template v-slot:item.fullname="{ item }">
          <div class="d-flex _justify-space-between align-center">

            <v-tooltip bottom v-if="item.error" color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" dark v-bind="attrs" v-on="on" class="mr-2">mdi-alert-circle-outline</v-icon>
              </template>
              <span>{{item.error}}</span>
            </v-tooltip>

            <router-link class="text-primary mr-2" :to="{ name: 'candidates-id', params: { id: item.id } }">
              <template v-if="item.last_name || item.first_name">{{ item.last_name }} {{ item.first_name }}</template>
              <template v-else>{{ item.id }}</template>
            </router-link>
          </div>
        </template>
        <!-- <template v-slot:item.email="{ item }">{{item.email}}</template> -->
        <template v-slot:item.test_date="{ item }">
          {{ $moment(item.test_date).format('DD.MM.YYYY') }}
        </template>

        <template v-slot:item.compliances="{ item }">
            <div class="">
                <span v-if="!item.compliances || !item.compliances.length">  </span>
                <v-menu v-else offset-y :close-on-content-click="false" ref="menuCompliance">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" class="comp-width-col font-xs" v-bind="attrs" v-on="on">
                      <div class="comp-table-btn">
                        <div>{{item.compliances[0].professiogram_name}}</div>
                        <div class="d-flex">
                          <div class="comp-circle">{{Math.round(item.compliances[0].compliance*100)}} </div>
                          <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon><v-icon v-else>mdi-chevron-up</v-icon>
                        </div>
                      </div>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(comp, i) in item.compliances.slice(0, 10)" :key="i">
                      <v-list-item-content>
                        <v-list-item-title class="font-sm">{{ comp.professiogram_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="comp-circle"> {{Math.round(comp.compliance*100)}} </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </div>
        </template>

        <template v-slot:item.prof_interests="{ item }">
            <div class="">
                <span v-if="!item.prof_interests || !item.prof_interests.length">  </span>
                <v-menu v-else offset-y :close-on-content-click="false" ref="menuProfInterests">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" class="comp-width-col font-xs" v-bind="attrs" v-on="on">
                      <div class="comp-table-btn interest">
                        <div>{{item.prof_interests[0].title}}</div>
                        <div class="d-flex">
                          <div class="comp-circle interest">{{item.prof_interests[0].points}} </div>
                          <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon><v-icon v-else>mdi-chevron-up</v-icon>
                        </div>
                      </div>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(comp, i) in item.prof_interests.slice(0, 10)" :key="i">
                      <v-list-item-content>
                        <v-list-item-title class="font-sm">{{ comp.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="comp-circle interest"> {{comp.points}} </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </div>
        </template>
        <template v-slot:no-data> Список пуст </template>
    </v-data-table>

  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AlaliticResultTab',
  components: {
  },
  data: () => ({
    tableHeight: 200,
    candidatesForExport: [],
    allHeaders: [
      { text: 'id', value: 'id', sortable: false},
      { text: 'Фамилия и Имя', value: 'fullname', _width: '280px', sortable: false},
      { text: 'Электронная почта', value: 'email', sortable: false},
      { text: 'Возраст', value: 'age', sortable: false},
      { text: 'Пол', value: 'str_gender', sortable: false},
      { text: 'Дата тестирования', value: 'test_date', sortable: false},
      { text: 'Должность', value: 'position', sortable: false},
      // { text: 'Название раздела', value: 'str_chapters', sortable: false},
      { text: '% соответствия', value: 'compliances', sortable: false},
      { text: 'Факторы риска', value: 'str_risk_factors', sortable: false},
      { text: 'Зоны внимания', value: 'str_attention_factors', sortable: false},
      { text: 'Достоверность', value: 'str_reliability', sortable: false},
      { text: 'Cтрессоустойчивость', value: 'str_stress_tolerance', sortable: false},
      { text: 'Ведущий стиль управления', value: 'str_leadership_styles', sortable: false},
      { text: 'Ведущая роль в команде', value: 'team_role', sortable: false},
      { text: 'Числовой интеллект', value: 'numeric_logic', sortable: false},
      { text: 'Вербальный интеллект', value: 'verbal_logic', sortable: false},
      { text: 'Невербальный интеллект', value: 'non_verbal_logic', sortable: false},
      { text: 'Интересы', value: 'prof_interests', sortable: false},
      { text: 'Компания', value: 'company', width: '150px', sortable: false},
      { text: 'Id клиента', value: 'corp_client_id', sortable: false},
    ],
    selectedHeaders: [],
  }),
  computed: {
    ...mapGetters({
      loading: 'analytics/loading',
      candidates: 'analytics/candidatesResult',
      user: 'auth/user',
    }),
    candidates2show() {
      return this.candidates // .filter(el => el.compliances);
    },
    headers2select() {
      return [...this.allHeaders];
    },
    showHeaders() {
      return this.allHeaders.filter(s => this.selectedHeaders.includes(s.value));
    },
  },
  methods: {
    ...mapActions({
      exportToExcelFull: 'profgrams/bulkcalc/exportToExcelFull',
    }),
    changeSelectedHeaders() {
      localStorage.setItem('showCandidateColumnsCompliance', JSON.stringify(this.selectedHeaders));
    },
    async toExcel() {
      await this.exportToExcelFull({ candidates: this.candidatesForExport.length ? this.candidatesForExport : this.candidates2show})
    }
  },
  async mounted() {
    this.selectedHeaders = JSON.parse(localStorage.getItem('showCandidateColumnsCompliance'));
    if (!this.selectedHeaders) {
      this.selectedHeaders = this.allHeaders.map(s => s.value);
    }
    const height = document.documentElement.clientHeight - 290;
    this.tableHeight = Math.max(height, this.tableHeight);
  }
};
</script>

<style scoped lang="scss">

.comp-circle {
  flex-shrink: 0;
  font-size: 11px;
  border-radius: 50%;
  background-color: #ff700a;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 10px !important;
  padding: 0 2px 0 0;
}

.comp-circle.interest {
  background-color: #007ef3;
}

.comp-table-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.comp-table-btn.interest {
  width: 300px;
}
</style>
