<template>
  <div>
    <h3 class="mb-2">Последние расчеты</h3>
     <div v-if="!calcs.length" class="list-empty">
          Список расчетов пуст
     </div>
     <v-data-table
          :headers="headers"
          :items="calcs"
          :options.sync="options"
          :server-items-length="totalCalcs"
          :loading="loading.calcList"
          class="elevation-1"
          show-select v-model="selected"
          hide-default-footer
          :_footer-props="{ showFirstLastPage: true }"
     >
      <template v-slot:item.position="{ item }">
          {{ getPosition(item.position) }}
      </template>
      <template v-slot:item.start_at="{ item }">
          {{ $moment(item.start_at).format('DD.MM.YYYY HH:mm:ss')  }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon v-if="item.candidates_count" @click="showResult(item)"><img src="@/assets/images/profile/arrowGo.svg" /></v-btn>
        <v-chip v-else color="error" small outlined>Ошибка расчета</v-chip>
      </template>
      <template v-slot:footer="{ props }">
        <v-data-footer
          :pagination="props.pagination"
          :options="props.options"
          items-per-page-text="Расчетов на странице:"
          :items-per-page-options="[10, 20, 50, 100, 500, 1000]"
          :page-text="`Всего: ${props.pagination.itemsLength}. Страница ${props.options.page} из ${props.pagination.pageCount}`"
          show-current-page
          @update:options="loadCalcs"
          show-first-last-page
        />
      </template>

    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import api from '@/common/api';

export default {
  name: 'CalculationsPanel',
  components: {
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
          { text: 'Дата', align: 'start', value: 'start_at', sortable: false, },
          { text: 'Должность', value: 'position', sortable: false, },
          { text: 'Количество кандидатов', value: 'candidates_count', sortable: false, align: 'center'},
          { text: 'Результат расчета', value: 'action', sortable: false, align: 'center'},
      ],
      selected : [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      positions: 'analytics/positions',
      loading: 'analytics/loading',
      calcs: 'analytics/calcs',
      totalCalcs: 'analytics/totalCalcs',
    }),
  },
  // watch: {
  //   options: {
  //     handler () {
  //       this.loadCalcs()
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions({
      loadCandidate: 'users/candidate/loadCandidate',
      loadResult: 'analytics/loadResult',
      loadCalcList: 'analytics/loadCalcList',
    }),
    ...mapMutations({
      setComplianceComputationSetups: 'analytics/setComplianceComputationSetups',
    }),
    async loadCalcs(pageOptions) {
      if (this.loading.calcList) {
        return;
      }
      if (pageOptions)
        this.options = pageOptions
      this.loadCalcList(pageOptions)
    },
    getPosition(id) {
      const pos = this.positions.find(el => el.id === id)
      return pos ? pos.title : ''
    },
    showResult(item) {
      const calc2show = this.selected.includes(item) ? this.selected.map(el => el.id) : [item.id]

      this.setComplianceComputationSetups(calc2show)
      this.loadResult()
      this.$emit('viewResult')
    }
  },
  created() {
    this.loadCalcs()
  },
};
</script>

<style scoped lang="scss">
.list-empty {
  color: #989FB9;
  font-size: 13px;
}
</style>
