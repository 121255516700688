<template>
  <div class="page pa-9">
    <!-- <h2 class="page-title mb-8 mt-1">Промокоды по реферальной программе</h2> -->
    <!-- <div class="profile-board ref-board"> -->
    <v-tabs-items v-model="mode" class="analytic-page">
      <v-tab-item value="start">
        <div class="row">
          <div v-for="panel in panels" :key="panel.title" class="col col-sm-4">
            <div class="info-panel">
              <div>
                <div class="info-panel__title">{{panel.title}}</div>
                <div class="info-panel__value">
                  <v-progress-circular v-if="loadingAnal[panel.key]" :size="20" color="primary" indeterminate/>
                  <span v-else>{{ analData[panel.key] }}</span>
                </div>
              </div>
              <img :src="panel.img" width="67px" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-12">
            <div class="profession-panel">
              <div class="d-flex justify-space-between mb-7">
                <div>
                  <div class="profession-panel__title">Соответствия профессии</div>
                  <div class="profession-panel__subtitle">Смотри соответствие кандидатов профессии</div>
                  <v-btn color="primary" class="mt-4" @click="mode='add'" :loading="loadingDataForCalc">Рассчитать</v-btn>
                </div>
                <img src="@/assets/images/analytics/born.svg" />
              </div>
              <CalculationsPanel @viewResult="mode='result'"/>
            </div>

          </div>
        </div>
      </v-tab-item>

      <v-tab-item value="add" class="_pa-4">
        <AnalyticsSetupTab @back="mode='start'" @viewResult="mode='result'"/>
      </v-tab-item>
      <v-tab-item value="result" class="_pa-4">
        <AnalyticsResultTab @back="mode='start'"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
/* eslint-disable */
import AnalyticsSetupTab from '@/components/analytics/AnalyticsSetupTab.vue';
import AnalyticsResultTab from '@/components/analytics/AnalyticsResultTab.vue';
import CalculationsPanel from '@/components/analytics/CalculationsPanel.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Analytics',
  components: {
    AnalyticsSetupTab, CalculationsPanel, AnalyticsResultTab
  },
  data() {
    return {
      mode: 'start',
      panels: [
        {
          title: 'Всего кандидатов',
          key: 'candidates_count',
          img: require('@/assets/images/analytics/green-chart.svg')
        },
        {
          title: 'Ожидают тестирования',
          key: 'number_of_not_endowed_candidatesMMPI',
          img: require('@/assets/images/analytics/green-chart.svg')
        },
        {
          title: 'Пройдено тестов',
          key: 'number_of_not_endowed_candidatesOther',
          img: require('@/assets/images/analytics/red-chart.svg')
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      analData: 'analytics/analData',
      positions: 'analytics/positions',
      // loadingCandidates: 'users/candidates/loading',
      loadingManagers: 'users/managers/loading',
      loadingClients: 'users/clients/loading',
      // managers: 'users/managers/managers',
      loadingAnal: 'analytics/loading',
      user: 'auth/user',
    }),
    loadingDataForCalc() {
      return this.loadingManagers.load || this.loadingAnal.profs || this.loadingClients.load
    }
  },
  methods: {
    ...mapActions({
      loadPositions: 'analytics/loadPositions',
      loadProfs: 'analytics/loadProfs',
      loadManagers: 'users/managers/loadManagers',
      loadClients: 'users/clients/loadClients',
      loadBalance: 'analytics/loadBalance',
      // loadAllCandidates: 'users/candidates/loadAllCandidates',
    }),
  },
  async mounted() {
    this.loadBalance()
    this.loadProfs()
    this.loadClients()
    this.loadPositions()
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="scss">

.analytic-page {
  background-color: transparent;
}
.ref-board {
  padding: 50px 40px;
}

.info-panel {
  border-radius: 10px;
  border: 1px solid #F2F4F5;
  background: #FFF;
  color: #25325F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  &__title {
    font-size: 14px;
    font-weight: 400;
  }
  &__value {
    font-size: 22px;
    font-weight: 600;
  }
}


.profession-panel {
  border-radius: 10px;
  border: 1px solid #F2F4F5;
  background: #FFF;
  color: #25325F;
  padding: 30px;
}

.profession-panel__title {
  font-size: 22px;
  font-weight: 600;
}

.profession-panel__subtitle {
  font-size: 16px;
  font-weight: 400;
}


</style>
