<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="candidates"
      class="px-0 _px-sm-5"
      sort-by="id"
      sort-desc
      dense
      :loading="loading.load"
      loading-text="Загрузка... Пожалуйста подождите"
      :page="filter.page"
      :items-per-page="filter.pageSize"
      :height="390"
      @pagination="pagination"
      hide-default-footer
      hide-default-header
      :server-items-length="count"
      :footer-props="{ itemsPerPageOptions: [10] }"
    >
      <template v-slot:header>
        <tr>
          <td class="border-bottom pa-4" >
            <div>
              <v-checkbox
                :input-value="selected.length"
                @change="onChangeAllCheck"
                :indeterminate="!!selected.length && selected.length !== count"
                hide-details
                class="mt-auto"
              />
            </div>
          </td>

          <td class="border-bottom pa-3" >
            <div>
              <v-text-field
                label="Фамилия и Имя"
                append-icon="mdi-magnify"
                class="font-sm"
                :value="filter.name"
                @input="debouncedSetFilterName"
                dense
                hide-details
                outlined
              />
            </div>
          </td>

        </tr>
      </template>

      <!--  Cells  -->
      <template v-slot:item.check="{ item }">
        <v-checkbox :input-value="selected.some(id => id === item.id)" @change="setSelected(item.id)" hide-details class="mt-auto" />
      </template>

      <template v-slot:item.fullname="{ item }">
        {{ item.last_name }} {{ item.first_name }}
      </template>

      <template v-slot:no-data> Кандидатов не найдено. </template>

      <template v-slot:footer="{ props }">
        <v-data-footer
          :pagination="props.pagination"
          :options="props.options"
          @update:options="updateOptions"
          items-per-page-text="На странице:"
          :items-per-page-options="[10, 20, 50, 100]"
          :page-text="`Всего: ${props.pagination.itemsLength}. Страница ${props.options.page} из ${props.pagination.pageCount}`"
          show-current-page
          show-first-last-page
        />
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'CandidatesTableSimple',
  components: {
  },
  props: {
    selected: Array,
  },
  data: () => ({
    tableHeight: 400,
    headers: [
      {
        text: '',
        value: 'check',
        sortable: false,
        width: '60px',
      },
      {
        text: 'Фамилия и Имя',
        value: 'fullname',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.name,
      },
    ],
    paginationData: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      candidates: 'users/candidates/candidates',
      filter: 'users/candidates/filter',
      count: 'users/candidates/count',
    }),
  },
  methods: {
    ...mapActions({
      setFilterPage: 'users/candidates/setFilterPage',
      setFilterPageSize: 'users/candidates/setFilterPageSize',
      setFilterName: 'users/candidates/setFilterName',
      setFilterCompany: 'users/candidates/setFilterCompany',
      setFilterManager: 'users/candidates/setFilterManager',
      setFilterAll: 'users/candidates/setFilterAll',
    }),
    ...mapMutations({
      setDefaultFilterPageSize: 'users/candidates/setFilterPageSize',
    }),

    setSelected(id) {
      const itemFound = this.selected.findIndex((item) => item === id);
      if (itemFound >= 0) {
        this.selected.splice(itemFound, 1);
      } else {
        this.selected.push(id);
      }
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      this.candidates.forEach(el => {
        const itemFound = this.selected.findIndex((item) => item === el.id);
        if (bool && itemFound < 0) {
          this.selected.push(el.id);
        } else if (!bool && itemFound >= 0) {
          this.selected.splice(itemFound, 1);
        }
      });
    },
    // eslint-disable-next-line func-names
    debouncedSetFilterName: debounce(function (val) {
      this.setFilterName(val);
    }, 500),
    updateOptions(param) {
      this.setFilterPageSize({ page: param.page, pageSize: param.itemsPerPage });
    },

  },
  watch: {
    filter: {
      handler(val) {
        const query = {};
        for (const [key, value] of Object.entries(val)) {
          if (Array.isArray(value) && value.length) {
            query[key] = value.join(',');
          } else if (value || value === 0) {
            query[key] = value;
          }
        }
      },
      deep: true
    },
  },
  created() {
    this.setDefaultFilterPageSize(10);
    this.setFilterAll({});
  },
};
</script>
<style lang="scss">
</style>
