<template>
  <div  v-click-outside="closePanel" class="right-panel" :class="{show: isPanelVisible}">
    <div class="right-panel__title">
      Соответствия профессии
    </div>
    <div class="right-panel__subtitle">
      Тут будет справка
    </div>
    <div class="right-panel__content-wrapper">
      Справка
    </div>

    <div class="right-panel__row mt-7">
      <v-btn class="right-panel__btn" @click="closePanel">Спасибо, я понял</v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters } from 'vuex';

export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
  },
  components: {
  },
  data: () => ({
    panelOpened: false,
  }),
  watch: {
    async isPanelVisible(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.panelOpened = true
        }, 300)
        // await this.load();
      }
      else
        this.panelOpened = false
    },
  },

  computed: {
    isPanelVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
    }),
    closePanel() {
      if (this.panelOpened)
        this.$emit('input', false);
    },
  }
}
</script>

<style>

.right-panel__content-wrapper {
  height: calc(100vh - 200px);
  overflow-y: auto;
  font-size: 10px;
  padding: 20px 0;
}


</style>
