<template>
  <v-dialog v-model="isDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="d-flex justify-center mt-7 mb-3">
        Формирование отчета
      </v-card-title>
      <v-card-text>
        <v-progress-linear :value="progress/total * 100"></v-progress-linear>
        <div class="d-flex justify-center mt-7 mb-3">
          <v-btn :loading="stopPressed" @click="stop()">Остановить</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AddProfrgamsDialog",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: Boolean,
    total: Number,
    progress: Number
  },
  data: () => ({
    stopPressed: false
  }),
  watch: {
    value(val) {
      if (val) {
        this.stopPressed = false
      }
    }
  },
  computed: {
    ...mapGetters({}),
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.close();
        }
      }
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    close(ret) {
      this.$emit("input", ret);
    },
    stop() {
      this.stopPressed = true
      this.$emit('stop')
    }
  }
};
</script>

<style>
.tree-view-container {
  height: 300px;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.selected-count {
  font-size: 12px;
}
</style>
