<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      class="simple-add-table mb-4"
      sort-by="company"
      sort-asc
      dense
      :loading="loading.load"
      loading-text="Загрузка... Пожалуйста подождите"
      :items-per-page="-1"
      :height="428"
      hide-default-footer
      hide-default-header
      :custom-sort="customSort"
    >
      <template v-slot:header>
        <tr>
          <td class="border-bottom pa-4" style="width: 60px">
              <v-checkbox
                :input-value="selected.length"
                @change="onChangeAllCheck"
                :indeterminate="!!selected.length && selected.length !== clients.length"
                hide-details
                class="mt-auto"
              />
          </td>

          <td class="border-bottom pa-3" >
            <v-text-field label="Фильтр" append-icon="mdi-magnify" class="font-sm" v-model="filterName" dense hide-details outlined />
          </td>
        </tr>
      </template>

      <!--  Cells  -->
      <template v-slot:item.check="{ item }">
        <v-checkbox :input-value="selected.some(id => id === item.id)" @change="setSelected(item.id)" hide-details class="mt-auto" />
      </template>

      <template v-slot:item.company="{ item }">
        {{ item.company }}
      </template>

      <template v-slot:no-data> Менеджеров не найдено. </template>

    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'clientsTableSimple',
  components: {
  },
  props: {
    selected: Array,
  },
  data: () => ({
    filterName: '',
    headers: [
      {  text: '', value: 'check', sortable: false, },
      {  text: 'Фамилия и Имя', value: 'company', sortable: false, },
    ],
  }),
  computed: {
    ...mapGetters({
      loading: 'users/clients/loading',
      clients: 'users/clients/clients',
    }),
    filteredItems() {
      return this.filterName ?
        this.clients.filter(el=> el.company.toLowerCase().includes(this.filterName.toLowerCase()))
        : this.clients
    }

  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),

    setSelected(id) {
      const itemFound = this.selected.findIndex((item) => item === id);
      if (itemFound >= 0) {
        this.selected.splice(itemFound, 1);
      } else {
        this.selected.push(id);
      }
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.clients.forEach(el => {
          const itemFound = this.selected.findIndex((item) => item === el.id);
          if (itemFound < 0) {
            this.selected.push(el.id);
          }
        });
      } else {
        this.selected.splice(0, this.selected.length);
      }
    },
    customSort(items, [index], [isDesc]) {
      items.sort((a, b) => {
        if (index === 'company') {
          const aname = a.company ? `${a.company}` : 'Я';
          const bname = b.company ? `${b.company}` : 'Я';
          return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },

  },
  created() {
  },
};
</script>

<style lang="scss">
</style>
