<template>
    <v-dialog v-model="isDialogVisible" max-width="500">
      <v-card>
        <v-btn class="button-close ma-4" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Выбор профессии
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="strFilter" prepend-inner-icon="mdi-magnify" label="Фильтр" placeholder="" outlined dense>
            <template v-slot:append>
               <v-btn text icon style="margin-top:-5px" @click="strFilter=''"><v-icon>mdi-close</v-icon></v-btn>
            </template>

          </v-text-field>
          <div class="tree-view-container">
            <v-treeview _open-all _return-object dense
              :items="visibleChapters"
              selectable v-model="selection" selection-type="leaf"
              :filter="filter" :search="strFilter"
            >
              <!-- <template v-slot:label="{ item }">
                <div :class="canAdd(item) ? 'item-normal' : 'item-disabled'" @click="addItem(item)">{{ item.name }}</div>
              </template> -->
              <template v-slot:label="{ item }">
                <div>
                  <span :class="{'font-weight-bold': item.children}">{{ item.name }}</span>
                  <span class='selected-count'>{{ getSelectedCount(item) }}</span>
                </div>
              </template>
            </v-treeview>
            </div>
            <div class="d-flex justify-space-between mt-7 mb-3" >
              <v-btn @click="close()">Отмена</v-btn>
              <v-btn @click="save()" color="primary">Применить</v-btn>
            </div>
          </v-card-text>
      </v-card>
    </v-dialog>

</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'AddProfrgamsDialog',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    srcSetup: Object,
  },
  data: () => ({
    strFilter: "",
    selection: [],
  }),
  watch: {
    async value(val) {
      if (val) {
        this.selection = cloneDeep(this.srcSetup.profs ?? [])
        // await this.loadCustomers();
      }
    },
  },
  computed: {
    ...mapGetters({
      profs: 'analytics/profs',
      chapters: 'analytics/chapters',
      loading: 'analytics/loading',
      selectedCandidates: 'users/candidates/selected',
      user: 'auth/user',
    }),
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.close()
        }
      },
    },
    visibleChapters() {
      return this.chapters;
      // return this.chapters.filter(el => this.$store.getters['profgrams/chapters/isChapterVisible'](el, this.user, false))
    },
    filter () {
        return (item, search, textKey) => {
          return item[textKey].indexOf(search) > -1
        }
      },

  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    close(ret) {
      this.$emit('input', ret);
    },
    save(ret) {
      this.srcSetup.profs = cloneDeep(this.selection)
      this.$emit('ok', this.srcSetup.profs);
      this.close()
    },
    getSelectedCount(item) {
      if (!item.children || !item.children.length)
        return;
      const cnt = item.children?.reduce((acc, el) => acc + (this.selection.includes(el.id) ? 1 : 0), 0)
      return cnt ? " ("+cnt+")" : '';
    }
  }

}
</script>

<style>
.tree-view-container {
  height: 300px;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.selected-count {
  font-size: 12px;
}
</style>
