<template>
  <AnalyticsMainPage />
</template>

<script>
/* eslint-disable */
import AnalyticsMainPage from '@/components/analytics/AnalyticsMainPage.vue';

export default {
  name: 'AnalyticsPage',
  components: {
    AnalyticsMainPage
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
</style>
